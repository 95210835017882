import React from "react";

const variantClasses = {
  h1: "font-bold sm:text-5xl md:text-5xl text-[98px]",
  h2: "sm:text-5xl md:text-5xl text-[64px]",
  h3: "font-normal text-[6.8px]",
  h4: "font-medium sm:text-[38px] md:text-[44px] text-[52px]",
  h5: "font-bold sm:text-[39.2px] md:text-[45.2px] text-[49.2px]",
  h6: "font-bold text-5xl sm:text-[38px] md:text-[44px]",
  body1: "font-medium sm:text-[35px] md:text-[41px] text-[45px]",
  body2: "font-medium sm:text-[33.2px] md:text-[39.2px] text-[43.2px]",
  body3: "sm:text-[32.2px] md:text-[38.2px] text-[42.2px]",
  body4:
    "font-bold sm:text-[31.200000000000003px] md:text-[37.2px] text-[41.2px]",
  body5: "font-bold sm:text-[30.67px] md:text-[36.67px] text-[40.67px]",
  body6:
    "font-bold sm:text-[30.200000000000003px] md:text-[36.2px] text-[40.2px]",
  body7: "font-bold sm:text-[35.2px] md:text-[37.2px] text-[39.2px]",
  body8: "font-normal sm:text-[35px] md:text-[37px] text-[39px]",
  body9: "font-bold sm:text-[34.4px] md:text-[36.4px] text-[38.4px]",
  body10: "font-bold sm:text-[34.2px] md:text-[36.2px] text-[38.2px]",
  body11: "font-bold sm:text-[33.4px] md:text-[35.4px] text-[37.4px]",
  body12: "text-4xl sm:text-[32px] md:text-[34px]",
  body13: "font-bold sm:text-[31px] md:text-[33px] text-[35px]",
  body14: "font-bold sm:text-3xl md:text-[32px] text-[34px]",
  body15: "font-bold sm:text-[29px] md:text-[31px] text-[33px]",
  body16: "md:text-3xl sm:text-[28px] text-[32px]",
  body17: "font-bold sm:text-[27px] md:text-[29px] text-[31px]",
  body18: "text-3xl sm:text-[26px] md:text-[28px]",
  body19: "sm:text-2xl md:text-[26px] text-[28px]",
  body20: "font-bold md:text-2xl sm:text-[22px] text-[26px]",
  body21: "font-bold sm:text-[21px] md:text-[23px] text-[25px]",
  body22: "text-2xl md:text-[22px] sm:text-xl",
  body23: "font-normal sm:text-[18.84px] md:text-[20.84px] text-[22.84px]",
  body24: "font-bold sm:text-[18.4px] md:text-[20.4px] text-[22.4px]",
  body25: "font-semibold sm:text-[17.94px] md:text-[19.94px] text-[21.94px]",
  body26: "font-bold sm:text-[17.4px] md:text-[19.4px] text-[21.4px]",
  body27: "text-xl",
  body28: "text-lg",
  body29: "text-[17.07px]",
  body30: "text-[17px]",
  body31: "font-bold text-[16.07px]",
  body32: "text-base",
  body33: "text-[15.2px]",
  body34: "font-normal text-[14.93px]",
  body35: "font-normal text-[14.4px]",
  body36: "font-bold text-[14.2px]",
  body37: "text-sm",
  body38: "font-bold text-[13.93px]",
  body39: "font-normal text-[13.5px]",
  body40: "font-bold text-[13.4px]",
  body41: "font-bold sm:text-5xl md:text-5xl text-[124px]",
  body42: "font-normal text-[12.5px]",
  body43: "font-bold text-[12.4px]",
  body44: "text-xs",
  body45: "font-normal text-[11.5px]",
  body46: "font-bold sm:text-5xl md:text-5xl text-[107.52px]",
};

const Text = ({ children, className, variant, as, ...restProps }) => {
  const Component = as || "span";
  return (
    <Component
      className={`${className} ${variant && variantClasses[variant]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
