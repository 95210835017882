/** @format */

import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer/Footer";
import { Img } from "./components/Img";
import "react-toastify/dist/ReactToastify.css";
import "./dark-mode.css";
import { ToastContainer } from "react-toastify";
import NotFound from "./NotFound";

const Home = React.lazy(() => import("./screens/Home/Home"));
const BlogDetails = React.lazy(() =>
  import("./screens/BlogDetails/BlogDetails")
);
const BookMeeting = React.lazy(() =>
  import("./screens/BookMeeting/BookMeeting")
);
const DevOpsServiceDetail = React.lazy(() =>
  import("./screens/DevOpsServiceDetail/DevOpsServiceDetail")
);
const Company = React.lazy(() => import("./screens/Company/Company"));
const ProjectDetails = React.lazy(() =>
  import("./screens/ProjectDetails/ProjectDetails")
);
const Services = React.lazy(() => import("./screens/Services/Services"));
const Portfolio = React.lazy(() => import("./screens/Portfolio/Portfolio"));
const Blogs = React.lazy(() => import("./screens/Blogs/Blogs"));
const Careers = React.lazy(() => import("./screens/Careers/Careers"));
const Terms = React.lazy(() => import("./screens/Terms/Terms"));
const Privacy = React.lazy(() => import("./screens/Privacy/Privacy"));
const ContactUs = React.lazy(() => import("./screens/ContactUs/ContactUs"));
const JobDetails = React.lazy(() => import("./screens/JobDetails/JobDetails"));
const StartUp = React.lazy(() => import("./screens/StartUp/StartUp"));
const HireDeveloper = React.lazy(() =>
  import("./screens/HireDevelopers/HireDeveloper")
);
const ApplyNow = React.lazy(() => import("./screens/ApplyNow/ApplyNow"));

const ProjectRoutes = () => {
  useEffect(() => {
    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    if (prefersDarkMode) {
      document.documentElement.classList.add("dark-mode");
    } else {
      document.documentElement.classList.remove("dark-mode");
    }
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <React.Suspense
        fallback={
          <div className="h-[100vh] w-[100vw] items-center flex justify-center">
            <Img src="images/loader.gif" alt="GIF Image" />
          </div>
        }
      >
        <Router>
          <Header />
          <Routes>
            <Route path="/company" element={<Company />} />
            <Route path="/home" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/blog-details" element={<BlogDetails />} />
            <Route path="/" element={<Home />} />
            <Route path="/devops" element={<DevOpsServiceDetail />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/project-details" element={<ProjectDetails />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/book-a-meeting" element={<BookMeeting />} />
            <Route path="/service-details" element={<DevOpsServiceDetail />} />
            <Route path="/job-details" element={<JobDetails />} />
            <Route path="/start-up" element={<StartUp />} />
            <Route path="/hire-developer" element={<HireDeveloper />} />
            <Route path="/apply-now" element={<ApplyNow />} />
            <Route path="/NotFound" element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
      </React.Suspense>
    </>
  );
};
export default ProjectRoutes;
