/** @format */

import Button from "../../components/Button";
import { Img } from "../../components/Img";
import { Text } from "../../components/Text";
import { header_options, services_types } from "../../constants/StaticData";
import React, { useState, useEffect } from "react";
import "./styles.css";
import { useLocation } from "react-router-dom"

const Header = (props) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = (dropdownName) => {
    setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
  };

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth > 899) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const navigateToPage = (path) => {
    window.location.href = path;
  };

  const renderDropdownContent = (dropdownName) => {
    if (dropdownName === "Services") {
      return (
        <div style={{ background: "#000000cc" }}
          className="absolute left-0 right-0 z-10  grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 grid-cols-3 gap-3 p-4 text-white"
        >
          {services_types.map((service, index) => (
            <div key={index} className="py-2">
              <a href={`/${service.route}`} className="block pointer font-redhatmono no-underline	hover:underline sm:ml-[0] ml-[22px] text-white text-left w-auto font-bold">
                <div className="flex items-center">
                  <span>{service.name}</span>
                </div>
              </a>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };


  return (
    <div className="d-flex flex-col">
      <header className='fixed-header'>
        <div className=' items-center justify-center w-full '>
          <div
            className='header-main items-center text-white pt-[20px] pb-[20px]'
            style={{ borderBottomWidth: 1, justifyContent: "space-between" }}>
            <div className='flex'>
              <div
                onClick={() => toggleMenu()}
                className=' items-center flex mobile'>
                <Img
                  src='images/Hamburger.png'
                  className='h-[35px] w-[35px] object-contain mb-[7px]'
                  alt='group'
                  height='52'
                />
              </div>
              <div
                className='justify-center justify-canter ml-[80px]'
                style={{ cursor: "pointer" }}
                onClick={() => navigateToPage("/home")}>
                <Img
                  src="images/pikessoftlogowhite.svg"
                  className='h-[52px] mb-[7px]'
                  alt='group'
                  height='52'
                  width='129'
                />
              </div>
            </div>
            {isOpen && (
              <div className='routes pl-[0px]  sm:gap-5 items-start justify-center'>

                {header_options.map((data, index) => (
                  <div key={index} className="mt-[7px] flex items-center justify-start group relative">
                    <Text
                      className={`${location.pathname === ('/' + data.route) ? 'hover' : ""} pointer font-redhatmono sm:ml-[0] ml-[22px] text-white text-left w-auto font-bold`}
                      variant='body37' onClick={() => navigateToPage("/" + data.route)}>
                      {data.name}
                    </Text>
                    {data.types && (
                      <svg className="pointer w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                      </svg>
                    )}
                    {data.types && (
                      <div className="absolute left-0 z-10 hidden group-hover:block min-w-[800px] sm:min-w-[400px] mt-[20px]" style={{ background: "#000000cc" }}>
                        {renderDropdownContent(data.name)} {/* Pass the correct dropdown name */}
                      </div>
                    )}
                  </div>
                ))}

              </div>
            )}

            <div
              className='web mr-[40px]'
              onClick={() => (window.location.href = "https://calendly.com/shahzaib-ede/30min")}>
              <Button label='Book a Meeting' Icon />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
