/** @format */

import FooterList from "../../components/FooterList/FooterList";
import { Img } from "../../components/Img";
import { Text } from "../../components/Text";
import { contact_us, resources, services } from "../../constants/StaticData";
import { all_right, grow_business } from "../../constants/StaticText";
import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

export default function Footer() {
  return (
    <footer>
      <div
        className='main-footer  pt-[90px]'
        style={{ borderTopWidth: 11, borderTopColor: "#FF5D52" }}>
        <div className='col-sm-12 col-md-6  justify-center items-center'>
          <div className='flex'>
            <Img
              src='images/Footer Abstract.webp'
              className=' h-[209px] left-[0] object-cover top-[20%] w-auto'
              alt='group_Twelve'
            />
            <div className='flex md:flex-1 flex-col gap-6 items-start justify-start w-[50%] grow-text md:w-full'>
              <Text
                className='font-bold font-figtree leading-[32.00px] text-left text-white_A700 w-full'
                variant='body22'>
                {grow_business}
              </Text>
              <div className=' common-pointer flex gap-[16px]'>
                <Link target='_blank' to={"https://www.linkedin.com/company/pikes-soft/"}>
                  <Img
                    src='images/linkedin.webp'
                    className='h-6 '
                    alt='frame7404'
                    height='24'
                    width='24'
                  />
                </Link>
                <Link target='_blank' to={"https://www.facebook.com/PikesSoft"}>
                  <Img
                    src='images/facebook.webp'
                    className='h-6 '
                    alt='frame7404'
                    height='24'
                    width='24'
                  />
                </Link>
                <Link target='_blank' to={"https://instagram.com/pikessoft2007?igshid=MzRlODBiNWFlZA=="}>
                  <Img
                    src='images/instagram.webp'
                    className='h-6 '
                    alt='frame7404'
                    height='24'
                    width='24'
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className='col-sm-12 col-md-6 flex service-list'
          style={{ flexDirection: "row-reverse" }}>
          <div className='flex gap-10 flex-wrap'>
            <FooterList data={services} heading='Services' />
            <FooterList data={resources} heading='Resources' />
            <FooterList data={contact_us} padding heading='Contact Us' />
          </div>
        </div>
      </div>
      <div className='pt-[42px]' style={{ backgroundColor: "black" }}>
        <div
          className='  all-right-reserved    items-center '
          style={{ borderTopWidth: 1, justifyContent: "space-between" }}>
          <div className='flex pt-[30px] pb-[30px]'>
            <Img
              src='images/img_map.svg'
              className='h-10 w-auto'
              alt='map_One'
            />
            <div className='flex flex-col pl-[10px]'>
              <Text
                className='font-spacemono  text-white_A700'
                variant='body32'>
                PikesSoft
              </Text>
              <Text className='font-spacemono  text-gray_500' variant='body37'>
                {all_right}
              </Text>
            </div>
          </div>
          <div className='flex common-pointer  justify-center items-center  pb-[30px] pt-[30px]'>
            <Text
              onClick={() => (window.location.href = "/privacy")}
              className='font-normal md:ml-[0] ml-[647px] not-italic text-gray_500 text-left w-auto'
              variant='body37'
              style={{ padding: 2 }}>
              Privacy
            </Text>
            <Text
              className='font-normal m-[10px]  not-italic text-gray_500 text-left w-auto'
              style={{ padding: 2,fontSize:20 }}>
              |
            </Text>
            <Text
              onClick={() => (window.location.href = "/terms")}
              className='font-normal  md:ml-[0] not-italic text-gray_500 text-left w-auto'
              variant='body37'
              style={{ padding: 2 }}>
              Terms
            </Text>
          </div>
        </div>
      </div>
    </footer>
  );
}
