import { Text } from '../../components/Text'
import React from 'react'

export default function FooterList({ data, heading, padding }) {

    const moveTo =(route)=>{

        if(heading === 'Services' || heading === 'Resources' ) window.location.href = `/${route}`
    }

    return (
        <div className=' common-pointer flex flex-col ' >
            <Text className="font-normal font-figtree  not-italic text-gray_500 text-left w-auto" variant="body37">
                {heading}
            </Text>
            {data.map((data,index) =>
                <Text key={index} onClick={()=>moveTo(data?.route)}  className="font-normal font-figtree  not-italic text-left text-white_A700 w-auto" style={{ paddingTop: padding ? 5 : 15 }} variant="body37">
                    {data?.name}
                </Text>
            )}
        </div>

    )
}
