import { Text } from "./components/Text";

export default function NotFound() {
  return (
    <div className='ml-[50px] mt-[200px] mb-[150px]'>
      <div className=' banner row col-12  w-full '>
        <div className='col-sm-12 col-md-7  mt-[19px]' >
          <div className='font-figtree '>
            <Text
              className='font-bold leading-[81.00px]  text-black_900  '
              as='h2'
              variant='h2'>
              Page Not Found
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}